.carousel-video-container {
    width: 100%;
    height: 70vh;
    background-color: #291608;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transform: scaleX(-1); /* Mirror the video horizontally */
}

.carousel-content {
    position: relative;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.carousel-item-content {
    
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
    padding: 10px;
    margin: auto;
    height: 100%;
}

.carousel-image-container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeInLeft 1.5s ease-in-out forwards;
    opacity: 0;
}

.carousel-item-content .carousel-image {
    width: 70%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.carousel-caption-container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: zoomInBottom 1.5s ease-in-out forwards;
    opacity: 0;
    margin-top: 50vh;
}

.carousel-caption {
    text-align: center;
    
}

.carousel-caption h2 {
    color: #A97D5D;
}

.carousel-caption p {
    color: rgba(255, 255, 255, 0.9);
    font-size: large;
    
}

.carousel-buttons {
    margin-top: 10px;
}

.carousel-button {
    margin: 10px;
}

.buy-button {
    background-color: #A97D5D;
    border: none;
    border: 2px solid #A97D5D;
}

.buy-button:hover {
    background-color:  rgba(41,22,8, 0.3);
    border: 2px solid #A97D5D;
}

.customize-button {

    background-color: rgba(92, 117, 94, 0.3);
    border: 2px solid #5C755E;
}

.customize-button:hover {
    background-color: #5C755E;
    border: 2px solid #5C755E;
}
.carousel-button:hover {
    opacity: 0.8;
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes zoomInBottom {
    0% {
        transform: translateY(100%) scale(0.5);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1100px) { 
    
}


@media only screen and (max-width: 767px) {
    .carousel-video-container .carousel-content .carousel-item-content {
        
        align-items: center;
        
        padding: 10px;
        position: relative;
        height: 100%;
    }
    .carousel-image-container {
        display: none;
        
    }
    
    .carousel-item-content .carousel-image {
        display: none;
    }
    
    .carousel-caption-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: zoomInBottom 1.5s ease-in-out forwards;
        opacity: 0;
        margin-top: 50vh;
    }
    
}