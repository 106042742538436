.related-products {
    text-align: center;
    margin: 20px auto;
    max-width: 1200px; /* Adjust max-width as necessary */
    padding: 0 20px;
}

.product-grid {
    display: grid;
    gap: 20px;
    justify-items: center;
}


.price-buy .add-to-cart {
    display: none;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.product-card:hover .add-to-cart {
    display: block;
}

/* Ensuring grid layout adjusts for different screen sizes */
@media (min-width: 1200px) {
    .product-grid {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .product-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 767px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    max-width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    background-color: white; /* Ensure background is white */
}

.product-card:hover {
    transform: scale(1.05); /* Slightly zoom out */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.product-card .img {
    height: 200px;
    overflow: hidden;
}

.product-card .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-card .related-product-name {
    font-size: 18px;
    margin: 10px 0;
    height: 2.5rem;
    overflow: hidden;
}

.product-card .product-content {
    font-size: 14px;
    color: #555;
    height: 80px; /* Adjust to a fixed height to ensure consistency */
    overflow: hidden;
}

.product-card .price-buy {
    display: block;
    align-items: center;
    height: 100px; /* Adjust to a fixed height to ensure consistency */
}

.product-card .price {
    font-size: 16px;
    font-weight: bold;
    color: #333; /* Change to preferred color */
}

.product-card .buy {
    margin-left: 10px;
}

.product-card .buy button {
    padding: 8px 15px;
    border: none;
    background-color: #5C755E;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 14px;
}

.product-card .buy a:hover {
    background-color: #4a5c4b;
    transform: scale(1.05);
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.navigation-buttons button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #5C755E;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


