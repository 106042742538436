.book-a-call {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.click-book {
  position: relative;
  padding: 0; /* Remove padding for icon centering */
  font-size: 0; /* Hide text content */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #5C755E;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.click-book i {
  font-size: 20px; /* Adjust icon size as needed */
  color: white;
  transition: color 0.3s ease;

}

.click-book:hover {
  background-color: white;
  color: #5C755E;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Beautiful shadow effect */
}

.click-book:hover i {
  color: #5C755E; /* Change color of <i> tag on hover */
}

.book-call {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
  color: #5C755E;
  font-size: xx-small; /* Adjust font size as needed */
  white-space: nowrap; /* Prevent wrapping */
  width: 80px; /* Adjust width as needed */
  shape-outside: circle(50%);
  clip-path: circle(50%);
}



