.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-overlay .popup-container {
    background: white;
    border: 2px solid #5C755E;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
.popup-overlay .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-overlay h2 {
    color: #A97D5D;
    text-align: center;
  }
  
.popup-overlay form {
    display: flex;
    flex-direction: column;
  }
  
  .popup-overlay label {
    margin-bottom: 10px;
  }
  
  .popup-overlay input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #5C755E;
    border-radius: 5px;
  }
  
  .popup-overlay  .subscribe-button {
    background-color: #A97D5D;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .popup-overlay .popup-container {
      width: 90%;
    }
  }
  