.packag {
  
  width: 80%;
  align-items: center;
  margin: 1rem auto 1rem auto;
  /* height: 86vh; */
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  padding: 2%;
  border: 0.5px solid white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.5);

}


.packages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  align-items: center;
  margin: 1rem auto 1rem auto;
  /* height: 86vh; */
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  padding: 2%;
  border: 0.5px solid white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.5);

}

.particle-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.packages .left {
  margin-top: 1rem;
  margin-right: 1rem;
  position: relative;
}

.packages .left img {
  height: auto;
  width: 100%;
}

.packages .right {
  height: auto;
  border-left: 1px solid rgba(169, 125, 93, 0.2);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  
  padding-left: 10px;
}


.packages .right .product-text p{
  z-index: 1;
  position: relative;
  width: 100%;
  
}


.packages .right .product-text h2 {
  font-size: xx-large;
  font-weight: 900;
  margin-bottom: 0.5rem;
  color: #A97D5D;
  
}

.sub-package-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
}

.sub-package-button {
  flex: 1;
  margin: 0 5px;
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #A97D5D;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  color: #A97D5D;
}

.sub-package-button:hover {
  background-color: rgba(92, 117, 94, 0.5);
  border: 1px solid #5C755E;
  color: white;
}

.sub-package-button.active {
  background-color: #5C755E;
  color: white;
}


.product-item-list{
  list-style: none; 
  color: black;
}

hr {
  
  color: #a97d5d;
  margin: 0px;
}

.packages .right .product-text{

  font-size: 20px;
  font-family: sans-serif;
  line-height: 30px;
  color: #5C755E;
  word-spacing: 1px;
  overflow: hidden;
  text-align: justify;
  text-justify: inter-word;

}

.remaining-items-dropdown {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10;
  list-style-type: disc;
  margin: 0;
  padding: 10px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto; 
}

.pricing {
  display: flex; 
  margin: 1rem 0;
}

.pricing div {
  text-align: center;
  flex: 1 1 auto;
  padding: 0.5rem;
  /* border: 1px solid #ddd;  */
  border: 1px solid #A97D5D;
  border-radius: 5px;
  margin: 0 0.5rem; 
  text-align: center; 
}

.pricing div:hover {
  text-align: center;
  flex: 1 1 auto;
  padding: 0.5rem;
  /* border: 1px solid #ddd;  */
  border: 1px solid #5C755E; 
  background-color: rgba(92, 117, 94, 0.5);
  border-radius: 5px;
  margin: 0 0.5rem; 
  text-align: center; 
  cursor: pointer;
}


.pricing div h5  {
 
  display: flex;
    justify-content: center;
    word-spacing: 13px;
    color: #A97D5D;
    font-weight: 900;
    font-size: 21px;
    text-decoration: none;
    margin-bottom: 0px;
}
.pricing div p {
 
  display: flex;
    justify-content: center;
    word-spacing: 13px;
    color: #5C755E;
    font-weight: 900;
    font-size: 21px;
    font-size: large;
    text-decoration: none;
    margin-bottom: 0px;
}

.pricing div h5:hover{
  color: white;
}

.pricing div p:hover {
  color: white;
}

.cart-glider {
  width: 100%;
  overflow: hidden;
  height: 60vh;
}

.cart-glider h2 {
  display: flex;
  justify-content: center;
  color: #A97D5D;
  font-size: 30px;
  font-weight: 500;
}

.cart-glider .slider {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  height: 50vh;
}

.cart-glider .slider .card {
  width: 30%;
  box-shadow: 0 1px 4px 0 #A97D5D;
  border-radius: 2rem;
  margin: 1rem;
  height: 90%;
}

.cart-glider .slider .card .img {
  height: auto;
  display: flex;
  width: auto;
  overflow: hidden;
}

.cart-glider .slider .card .img img {
  height: auto;
  width: auto;
  object-fit: cover;
  object-position: top center;
}

.cart-glider .slider .card .text {
  height: 30%;
  margin-left: auto;
  width: 90%;
  margin-right: auto;
}

.cart-glider .slider .card .text h2 {
  font-size: 20px;
}

.price-buy {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}

.cart {
  width: 100%;
  overflow: hidden;
}

.cart h2 {
  display: flex;
  justify-content: center;
  color: #A97D5D;
  font-size: 30px;
  font-weight: 700;
}

.cart .product {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: fit-content;
  overflow: hidden;
  position: relative;
}

.cart .product .cards {
  width: 80%;
  height: fit-content;
  box-shadow: 0 1px 4px 0 #A97D5D;
  border-radius: 2rem;
  margin: 1rem;
}


.cart .product .cards .img {
  height: 10rem;
  width: 100%;
  overflow: hidden;
}

.cart .product .cards .img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center center;
}

.cart .product .cards .text {
  height: 30%;
  margin-left: auto;
  width: 90%;
  margin-right: auto;
}

.cart .product .cards .text h2 {
  font-size: 20px;
}

.price-buy {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.slider button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider button.glider-prev {
  left: 1rem;
  color: rgb(160, 160, 160);
  padding: 15px;
}

.slider button.glider-next {
  right: 1rem;
  color: rgb(160, 160, 160);
  padding: 15px;
}

button.glider-next:hover,
button.glider-prev:hover {
  background: transparent;
  color: white;
}

.buy {
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy .button-link {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #A97D5D;
  height: 3rem;
  width: 8rem;
  text-decoration: none;
  color: #5C755E;
  border-radius: 10px;
  background-color: white;

}

.buy .button-link:hover{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5C755E ;
  height: 3rem;
  width: 8rem;
  text-decoration: none;
  color: #A97D5D;
  border-radius: 10px;
  background-color: rgba(65, 170, 20, 0.05);

}

.packages .right-2 {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60% 35%;
}

.packages .right-2 img {
  height: 100%;
  width: 100%;
}

.packages .right-2 .other-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.related-products{
  background-color: white;
  width: 90%;
  align-items: center;
  align-content: center;
  margin-left: 5%;
}

.related-products .related-product-content{
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-items: center;margin-top: 20px;
  z-index: 10;
}

.related-product-name{

  font-size: large;
  color: #A97D5D;
}

.related-products .cards{
  padding: 20px;
}

.related-products .cards img{
  
  border: 2px solid rgba(169, 125, 8, 0.4);;
  display: block;
  object-fit: contain;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  
}

.related-products .cards .img:hover {
  opacity: 0.6;
  transform: scale(1.1); 
  cursor: pointer; 
}

.related-product .cards .product-content{
  color: #5C755E;
}

.related-products .price-buy .buy{
  color: #5C755E;
}

/* ESTATE PLANS STYLE START HERE*/

.carouse {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.carouse-wrapper {
  display: flex;
  transition: transform 1s ease-in-out;
  width: 100%;
}

.slid {
  flex: 1 0 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.slid.active {
  opacity: 1;
}

.carouse-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carouse-control.prev {
  left: 5px;
}

.carouse-control.next {
  right: 5px;
}

/* ESTATE PLANS STYLE ENDS HERE */

@media only screen and (min-width: 768px) and (max-width: 1100px) {


  .packages {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    width: 90%;
    align-items: center;
    margin: 1rem auto 1rem auto;
    height: 105vh;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    padding: 2%;
    border: 0.5px solid white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.5);
  
  }

  .particle-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .packages .left {
    margin-top: 2rem;
    margin-right: 1rem;
    position: relative;
  }

  .packages .left .text {
    z-index: 1;
    position: relative;
  }


  .packages .left .text h2 {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 2rem;
    color: #A97D5D;
  }

  hr {
    margin-top: 3rem;
    color: #a97d5d;
  }

  .packages .left .text,
  .packages .left .pricing h5,
  .packages .left .pricing h6,
  .packages .left .pricing span {
    font-size: 20px;
    font-family: sans-serif;
    line-height: 30px;
    word-spacing: 1px;
  }

  .packages .left .pricing h5,
  .packages .left .pricing h6 {
    font-size: 23px;
    font-weight: 900;
    color: #A97D5D;
  }

  .packages .left .pricing span {
    margin-bottom: 1rem;
  }

  .packages .right {
    height: 100%;
  }

  .packages .right img {
    height: 100%;
    width: 100%;
  }
}



@media only screen and (max-width: 767px) {
  .packages {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin: 1rem auto 1rem auto;
    height: fit-content;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
  }

  .particle-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .packages .left {
    margin-top: 0.2rem;
    margin-right: 0.2rem;
    position: relative;
  }

  .packages .left .text {
    z-index: 1;
    position: relative;
  }


  .packages .left .text h2 {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 1rem;
    color: #A97D5D;
  }

  hr {
    margin-top: 1rem;
    color: #a97d5d;
  }

  .packages .left .text,
  .packages .left .pricing h5,
  .packages .left .pricing h6,
  .packages .left .pricing span {
    font-size: 20px;
    font-family: sans-serif;
    line-height: 30px;
    word-spacing: 1px;
  }

  .packages .left .pricing h5,
  .packages .left .pricing h6 {
    font-size: 23px;
    font-weight: 900;
    color: #A97D5D;
  }

  .packages .left .pricing span {
    margin-bottom: 0.2rem;
  }

  .packages .right {
    height: 100%;
  }

  .packages .right img {
    height: 100%;
    width: 100%;
  }

  .packages .right .product-text h2 {
    font-size: x-large;
    font-weight: 900;
    margin-bottom: 0.5rem;
    color: #A97D5D;
}

  .pricing div {
    text-align: center;
    /* border: 1px solid #ddd;  */
    border: 1px solid #A97D5D;
    border-radius: 5px;
    padding: 0px 5px;
    text-align: center; 
    margin: 0 0.5rem;
  }
  
  .pricing div:hover {
    
    /* border: 1px solid #ddd;  */
    border: 1px solid #5C755E; 
    background-color: rgba(92, 117, 94, 0.5);
    border-radius: 5px;
   
  }
  
  
  .pricing div h5  {
   
    display: flex;
      justify-content: center;
      align-items: center;
    
      color: #A97D5D;
      font-weight: 900;
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 0px;
  }
  .pricing div p {
   
    display: flex;
      justify-content: center;
      align-items: center;
    
      color: #5C755E;
      font-weight: 900;
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 0px;
  }
  
  .pricing div h5:hover{
    color: white;
  }
  
  .pricing div p:hover {
    color: white;
  }
}