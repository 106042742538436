.Admin-chat-container {
  position: fixed;
  bottom: 0px;
  border-radius: 20px;
  width: 30vw;
  height: 50vh;
  border: 2px solid #A97D5D;
  background-color: white;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 10px;
}

.Admin-chat-container h2 {
  font-size: large;
  margin-bottom: 0px;
}

.Admin-chat-container h3 {
  font-size: small;
  margin-bottom: 0px;
}

.Admin-chat-container h4 {
  font-size: small;
  margin-bottom: 10px;
}

.Admin-chat-container .user-chats {
  height: 35vh;
  overflow: hidden;
  overflow-y: scroll;
}

.Admin-chat-container .user-chats p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.Admin-chat-container .user-chats em {
  font-size: small;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Admin-chat-container .Admin-chat-input-button {
  position: absolute;
  bottom: 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  overflow: hidden;
  margin-bottom: 5px;
}

.Admin-chat-container .Admin-chat-input-button button {
  background-color: #A97D5D;
  color: white;
  border: 1px solid #A97D5D;
}