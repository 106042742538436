.social-media-feeds {
    background-color: rgba(0, 0, 0, 0.01);
    text-align: center;
  }
  
  .buttons {
    margin-bottom: 20px;
  }
  
  .social-media-feeds .buttons2 {
    display: none;
  }

  .buttons button {
    margin: 0 10px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #5C755E;
    border: 1px solid #5C755E;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .buttons button.active {
    background-color: white;
    color: #5C755E;
  }
  
  .feed {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  
  }


  @media only screen and (min-width: 768px) and (max-width: 1100px) {


    .social-media-feeds {
      margin: 0px;
      
    }
    
    .buttons {
      margin-bottom: 20px;
    }
    
    .buttons button {
      margin: 0 10px;
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      background-color: #5C755E;
      border: 1px solid #5C755E;
      color: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  
    .buttons button.active {
      background-color: white;
      color: #5C755E;
    }
    
    .feed {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
    
    }
    
  }
  


  @media only screen and (max-width: 767px) {

    .social-media-feeds {
      margin: 0px;
      margin-right: 0px;
      
    }
    
    .social-media-feeds .buttons {
      display: none;
    }

    .social-media-feeds .buttons2 {
      display: flex;
      margin-bottom: 20px;
      margin-right: 0px;
    }
    
    .buttons2 button {
      margin: 0 10px;
      padding: 5px 10px;
      font-size: 10px;
      cursor: pointer;
      background-color: #5C755E;
      border: 1px solid #5C755E;
      color: white;
      margin: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  
    .buttons2 button.active {
      background-color: white;
      color: #5C755E;
    }
    
    .feed {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
    
    }
    
  }