.instagram-feed-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Ensure no scrolling */
    scrollbar-width: 0px;
  }
  
  .lightwidget-widget {
    width: 100%;
    height: 100%;
    border: 0;
    overflow: hidden; /* Ensure no scrolling within iframe */
  }
  