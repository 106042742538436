/* ItemDetails.css */

.item-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.item-details {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.item-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
}

.item-image {
    max-width: 50%;
    height: 50%;
    border-radius: 10px;
}

.item-info {
    padding: 20px;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 1px solid #4a5c4b;
}

.item-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.item-description {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

.item-amount {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.add-to-cart-button {
    background-color: #68aa6c;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
    background-color: #4a5c4b;
}
.back-button {
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.1);
    border: 0px solid ;
    position: fixed;
    color: rgba(169,125,93, 0.7);
    z-index: 10;
    transition: background-color 0.3s ease;
}

.back-button span {
    font-size: x-large;
}


/* Responsive style for mobile */
@media only screen and (max-width: 767px) {

    .item-image-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: #f9f9f9;
        padding: 20px;
    }

    .back-button {
        margin-right: auto;
        background-color: rgba(0, 0, 0, 0.1);
        border: 0px solid ;
        position: fixed;
        color: #ffffff;
        z-index: 10;
        border-radius: 5px;
    }

    .back-button span {
        font-size: x-large;
    }

}

