.contactChatBox {
  width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

.chatbox-contact{
  font-size: xx-large;
  color: #A97d5d;
}

.chatboxHeader {
  text-align: center;
}

.chatboxBody {
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  margin-bottom: 10px;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.startChatBtn {
  background-color: #5c755e;
  color: #fff;
  border: 1px solid #5c755e;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
}

.startChatBtn:hover {
  background-color: white;
  color: #5c755e;
  transition: ease-in-out 0.2s;
}

.chatInterface {
  display: flex;
  flex-direction: column;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.message.admin {
  background-color: #d1e7dd;
  align-self: flex-start;
}

.message.user {
  background-color: #bee5eb;
  align-self: flex-end;
}

.inputGroup {
  display: flex;
}

.inputField {
  flex-grow: 1;
  margin-right: 10px;
}

.sendBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.sendBtn:hover {
  background-color: #0056b3;
}
