*{
    box-sizing: border-box;
    margin: 0;
}

#navbarSupportedContent {
  align-items: center;
}

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition : all 0.3s ease-out;
  transition : all 0.3s ease-out;
}
.start-header.scroll-on {
box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition : all 0.3s ease-out;
  transition : all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img{
  height: 24px;
  -webkit-transition : all 0.3s ease-out;
  transition : all 0.3s ease-out;
}
.navigation-wrap{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition : all 0.3s ease-out;
  transition : all 0.3s ease-out;
}
  .navbar{
    padding: 0;
    justify-content: space-between;
    display: flex;
  }
  .navbar-brand img{
   height: 60px;
   width: 100%;
    display: block;
    filter: brightness(100%);
    -webkit-transition : all 0.3s ease-out;
    transition : all 0.3s ease-out;
  }
  .navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;
  }
  .navbar-toggler:active,
  .navbar-toggler:focus {
    outline: none;
  }
  .navbar-light .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 1px solid #5C755E;
      transition: all 300ms linear;
  }
  .navbar-light .navbar-toggler-icon:after, 
  .navbar-light .navbar-toggler-icon:before{
    width: 24px;
    position: absolute;
    height: 1px;
    background-color:#5C755E;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
      transition: all 300ms linear;
  }
  .navbar-light .navbar-toggler-icon:after{
    top: 8px;
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
  }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }
  .nav-link{
    color: #212121 !important;
    font-weight: 500;
      transition: all 200ms linear;
  }

  

  .nav-item:hover .nav-link{
    color: #8167a9 !important;
  }
  .nav-item.active .nav-link{
    color: #777 !important;
  }
  .nav-link {
    position: relative;
    padding: 5px 0 !important;
    display: inline-block;
    margin-right: 1rem;
    
  
  }
  .nav-item:after{
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background-color: #8167a9;
    opacity: 0;
      transition: all 200ms linear;
  }
  .nav-item:hover:after{
    bottom: 0;
    opacity: 1;
  }
  .nav-item.active:hover:after{
    opacity: 0;
  }
  .nav-item{
    position: relative;
      transition: all 200ms linear;
      justify-content: space-between;
      align-items: center;
      display: flex;
  }

.search-bar {
  display: flex;
  align-items: center;
}


.search-bar input {
  padding: 5px;
  font-size: 16px;
  border: 2px solid #5C755E; /* Customize the border color */
  border-radius: 5px; /* Customize the border radius */
  outline: none;
  transition: border-color 0.3s ease;
}

.search-bar input:focus {
  border-color: #A97D5D; /* Customize the border color on focus */
}

.search-bar button {
  padding: 10px;
  font-size: 16px;
  margin-left: 5px;
  background-color: #5C755E;
  border: 1px solid #5C755E;
  color: white;
  border-radius: 5px;
}


.search-results {
  margin-top: 20px;
  height: 50vh;
  width: 100%;
}

.search-results h2 {
  margin-bottom: 10px;
}

.search-results div {
  margin-bottom: 10px;
}



  .buttons {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
    margin-right: 1rem; 
}

.buttons input{
  background: transparent;
  border: 1px solid #A97D5D;
  height: fit-content;
  width: fit-content;
  padding: 5px 25px;
  border-radius: 1rem;
}

.cart-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
}

.cart-icon img {
  width: 24px;
  height: 24px;
}

.item-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.4em;
  font-size: 0.8em;
}

/* Mobile styles */
@media (max-width: 767px) {
  .cart-icon {
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: 1000; 
  }
  .cart-icon img {
    width: 36px;
    height: 36px;
  }

  .navbar-toggler {
      margin-right: 50px; 
  }
}

/* mobile style end here */



.background-image {
  background: url('/public/assets/img/logo.png') no-repeat center center fixed;
  background-size:contain;
  width: 100%;
  height: 100%;
  position: fixed;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.1; /* Adjust the opacity to make the image faint */
}

.start {
  width: 100vw;
  height: 80vh;
}

.start0{
  /* width: 100%; /* Ensure full width coverage */
  /* height: 80vh; */
  width: auto;
  display: flex;
  height: 80vh;
  background: url(../public/assets/img/Banner/Grandeur_banner_sensors.png) no-repeat center top fixed;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;
  overflow: hidden;
  
}

.start1{
  /* width:100%;
  height: 80vh; */

  width: auto;
  display: flex;
  height: 80vh;
  background:url(../public/assets/img/Banner/dalle-3IfVZFAC0E.png) no-repeat center top fixed;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;
  overflow: hidden;
}


.start2{
  width: auto;
  display: flex;
  height: 80vh;
  background:url(../public/assets/img/Banner/Grandeur_smart_banner_2a.jpg) no-repeat center top fixed;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;
  
  animation: reveal-image 2s ease-in-out infinite;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  overflow: hidden;
}

.start3{
  width: auto;
  display: flex;
  height: 80vh;
  background:url(../public/assets/img/Banner/grdnr_prdt_re.jpg) no-repeat center top fixed;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;

  overflow: hidden;
}


.start4{
  width: auto;
  display: flex;
  height: 80vh;
  background:url(../public/assets/img/Banner/Granduer_product_Gateway22-1.png) no-repeat center top fixed;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  transform-style: preserve-3d;
  overflow: hidden;
}


.start .slider{
    width: 60%;
  background: rgba(255, 255, 255, 0.449);
  padding: 0px 10px;
  height: fit-content;
  }

.start .slider h2{
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    color:#A97D5D;
    display: flex;
    margin-top: 5rem;
    width: 80%;
    font-family: sans-serif;
    font-weight: 900;
}

.start .slider p{
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: #5C755E;
    display: flex;
    margin-top: 3rem;
    width: 80%; 
    font-family: sans-serif;
    text-align: justify;
    text-justify: inter-word;
}

.start .slider .buttons{
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
}

.start .slider .buttons input{
  background: #5C755E;
    height: 3rem;
    width: 6rem;
    border: 1px solid white;
    border-radius: 1rem;
    color:white;
}

.gateway-section {
  position: relative;
  
}

.gateway-section h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 13px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}



#gatewayCanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gateway-section .Image-text {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 75vh; */
  border-radius: 0.8rem;
  width: 85%;
  margin: 3rem auto;

}

.image {
  width: 45%;
  height: 60vh;
  overflow: hidden;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

.text {
  width: 45%;
  /* height: 60vh; */
  overflow: hidden;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

h5, .text h3 {
  display: flex;
  justify-content: center;
  word-spacing: 13px;
  color: #A97D5D;
  font-weight: 900;
  font-size: 21px;
}

.text .gate-paragraph {
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify: inter-word;
}

.bullet1 li  {
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify: inter-word;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.read-more {
  animation: pulse 3s infinite;
  text-decoration: none;
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #A97D5D;
  list-style: none;
  cursor: pointer;
  
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify: inter-word;
  font-weight: bold;
  font-size: medium;
}

.read-more:hover{
  color: #5C755E;
}




.Products{
  background: transparent;
  height: 50vh;
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
  margin-top: 2rem;
  width: 100%;
}
/* .Products .more{
  font-size: medium;
} */


.Products .product-slider{
  display: flex;
  margin-left: auto;
  margin-right: auto; 
  height: 50vh;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.Products h2 .more{
  text-decoration: none;
  justify-content: flex-end;
  position: absolute;
  right: 5%; 
  color: #5C755E;
  word-spacing: 10px;
  font-size: medium;
  margin-top: 53%;
  z-index: 10;
}

.Products h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 13px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.Products .product-slider{
  width: 95%;
}
.Products .product-slider .product{
  height: 40vh;
  width: 90%;
  margin-right: 0.4rem;
  margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  align-content: center;
  justify-items:center;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}
.Products .product-slider .product img{
  width: 100%;
  height: 100%;
  
}

.Products .product-slider .product  h3{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color:  #5C755E;
  font-weight: 400;
  font-size: large;
  width: 100%;
}


.our-partners {
  width: 70%;
  height: 30vh;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  margin-bottom: 3rem;
}

.our-partners h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
/* was .our-partners .img{} and .our-partners .img img{} before */
.our-partners .image{
  display: flex;
  width: auto;
  height:auto;
  justify-content: space-between;
}


.our-partners .image img{
  width: auto;
  height:auto;
}

.Mobile-app {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-top: 5%;
}
.Mobile-app .heading{
  background-color: #fff;
}
.Mobile-app img {
  height: auto;
  width: auto;
}

.Mobile-app h2 {
  justify-content: center;
  display: flex;
  color: #A97D5D;
}

div .features {
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
}

.features h3{
  display: flex;
  justify-content: center;
  word-spacing: 13px;
  color: #A97D5D;
  font-weight: 900;
  font-size: 21px;
}


.bullet2 li  {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500 ;
  text-align: justify;
  text-justify: inter-word;
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
}

#mobileCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.partition{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0%;
}

.partition .part1{
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  
}


.partition .part1 .logo{
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  
}

.partition .part1 .logo img{
  width: 100%;
  height: 100%;
}
.partition .part2{
  display: grid;
  grid-template-columns: 1.2fr 0.75fr;
  justify-content: center;
  align-items: flex-start;
  gap: 7rem;
  width: 100%;
  justify-content:center;
  align-items: center;
  height: fit-content;
}

.partition .part2 .tt p{
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500 ;
  text-align: justify;
  text-justify: inter-word;
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
}
.partition1 {
  display: none;
}
.msmart{
  width: 25%;
  height: 30%;
} 


.helpful-link2 {
  display: none;
}


.blog h2{
  display: flex;
  justify-content: center;
  color: #A97D5D;
}
.blog{
  height: 80vh;
  width: 100%;
}
.blogs{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 70vh;
  border-radius: 1rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.Blog-card{
  width: 90%;
  height: 80%;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 0.2rem;
  overflow: hidden;
  border-top-right-radius: 2rem;
  overflow: hidden;
}

.Blog-card .img{
  width: 100%;
  height: 35vh;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 2rem;
  overflow: hidden;
  display: block;
}


.Blog-card .img img{
  width: 100%;
  height: 100%;
}

.Blog-card .text{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
  
}

.Blog-card .text h2{
  width: 90;
  font-size: 15px;
  color: #5C755E;
  position: relative;
  text-align: justify;
  text-overflow: ellipsis;
  justify-content:space-around ;
  overflow: hidden;

  @supports (-webkit-line-clamp: 4) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.Blog-card .text .read-more {
  position: relative;
  bottom: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  color: blue;
  cursor: pointer;
}

.Blog-card .text p{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  
}


.mg-txt {
  display: grid;
  grid-template-columns: 70% 30%;
  width: 90%;
  margin-left: auto;
  height: fit-content; 
  /* url(public/assets/img/Banner/Granduer_product_Gateway22.png); */
  overflow: hidden;
  margin-right: auto;
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: relative;
}

.mg-txt .img {
  width: 100%;
  height: 100%;
  margin-right: 1rem;
}

.mg-txt .img img {
  height: 100%;
  width: 100%;
}

.mg-txt .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: 100%;
  word-spacing: 10px;
  font-size: 20px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto 2rem;
  position: relative;
}

.mg-txt .txt p{
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify:distribute-all-lines;
}

.featureHead{
  font-weight: bolder;
  list-style-type: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  word-spacing: 8px;
  font-family: Arial;
  color: #A97D5D;
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify: inter-word;
}

.featureSubH{
  font-weight: bold;
  margin-right: 5px;
}
.link-text-deco {
  text-decoration: none;
}






/*
Contact Doctor Area Style
======================================================*/
.contact-doctor-area {margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
  /* color: rgb(108, 108, 108); */
  text-align: justify;
  text-justify: inter-word;
margin-top: 2rem;
  overflow: hidden;
}

.contact-doctor-contain .contact-title span {
  color: #A97D5D;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.contact-doctor-contain .contact-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}
.contact-doctor-contain .contact-title p a{
  text-decoration: none;
  color: #A97D5D;
  font-size: 20px;
}
.contact-doctor-contain .contact-title p {
  margin-bottom: 10px;
  display: flex;
}
.contact-doctor-contain .contact-title span{
color: black;
font-size: 20px;
}
.contact-doctor-form .form-group {
  margin-bottom: 25px;
}

.contact-doctor-form .default-btn {
  width: 40%;
  background :transparent;
  border: 2px solid #5C755E;
  color: white;
  background-color: #5C755E;
  border-radius: 5px;
  height: 2rem;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}


.contact-doctor-form .default-btn:hover {
  color: #5C755E;
  background-color: rgba(255,255,255, 0.4);
}

.last{
  display: flex;
  gap: 1rem;
}

.last img{
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  border: 1px solid #A97D5D;
  border-radius: 5px;
}
/*

/*
Footer Doctor Area Style
======================================================*/
.footer-area {
  background-color: transparent;
}

.logo-area {
  margin-bottom: 30px;
  padding-right: 30px;
}

.align-items-cente {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contact-title h2{
  color: #A97D5D;
}
.contact-title p{
  color: #5C755E;
}
.logo-area img {
  margin-bottom: 30px;
  height: 60px;
}

.logo-area p {
  color: #5C755E;
  margin-bottom: 30px;
}

.logo-area .newsletter-form {
  position: relative;
  display: flex;

  align-items: baseline;
}

.logo-area .newsletter-form .form-control {
  height: 53px;
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.45);
  color: #A97D5D;
  overflow: hidden;
}

.logo-area .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 80%;
  background-color: #A97D5D;
  color: white;
  border: 1px solid #5C755E;
  border-radius: 10px;
  padding: 2px 10px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.logo-area .newsletter-form .default-btn:hover {
  background-color: rgba(0,0,0, 0.7)
}


.quick-link {
  margin-bottom: 30px;
}

.quick-link h3 {
  font-size: 20px;
  color: #A97D5D;
  margin-bottom: 30px;
}

.quick-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.quick-link ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 13px;
  color: #5C755E;
}

.quick-link ul li i {
  position: absolute;
  left: 0;
  color: #5C755E;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li p {
  color: #5C755E;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li:last-child {
  margin-bottom: 0;
}

.quick-link ul li:hover i {
  color: #5C755E;
}

.quick-link ul li:hover p {
  color: #5C755E;
}

.helpful-link {
  margin-bottom: 30px;
}

.helpful-link h3 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #A97D5D;
}

.helpful-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.helpful-link ul li {
  list-style-type: none;
  color: #5C755E;
  margin-bottom: 20px;
}

.helpful-link ul li span {
  color: #A97D5D;
  font-weight: 500;
  padding-left: 5px;
  display: inline-block;
}

.helpful-link ul li:last-child {
  margin-bottom: 0;
}

.helpful-link ul li a {
  color: #5C755E;
}


.copyright {
  border-top: 1px solid #A97D5D;
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 0fr;
  justify-content: center;
  align-items: center;
  
}

.copyright .copy p {
  color: #A97D5D;
  text-align: center;
  
}

.copyright .copy p a {
  color: #A97D5D;
  text-decoration: none;
}

.copyright .social-content {
  text-align: right;
}

.copyright .social-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.copyright .social-content ul li {
  display: inline-block;
  margin-right: 10px;
}

.copyright .social-content ul li:last-child {
  margin-right: 0;
}

.copyright .social-content ul li span {
  color: #A97D5D;
}

.copyright .social-content ul li a {
  color: #5C755E;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.copyright .social-content ul li a:hover {
  color: #A97D5D;
}







@media only screen and (min-width: 768px) and (max-width: 1100px) {
  
  .navbar-brand img{
    padding: 5px;
    height: 40px;
    width: 100%;
     display: block;
     filter: brightness(100%);
     -webkit-transition : all 0.3s ease-out;
     transition : all 0.3s ease-out;
   }
   .cart-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000; 
  }
  .cart-icon img {
    width: 36px;
    height: 36px;
  }

  .navbar-toggler {
    margin-right: 50px; 
  }



  .start{
    width: 100%;
    display: flex;
    height: 50vh;
    background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
    background-size: fill;
    background-position: center center;
    background-attachment: fixed;
}

.start1{
  width: 100%;
  display: flex;
  height: 50vh;
  background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
}


.start2{
  width: 100%;
  display: flex;
  height: 50vh;
  background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
}
.start .slider,
.start1 .slider,
.start2 .slider{
    width: 100%;
background: rgba(255, 255, 255, 0.449);

height: 70%;
}

.start .slider h2{
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    color:#A97D5D;
    display: flex;
    margin-top: 2rem;
    width: 95%;
    font-family: sans-serif;
    font-weight: 900;
}

.start .slider p{
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: #A97D5D;
    display: flex;
    margin-top: 2rem;
    width: 100%; 
    font-family: sans-serif;
    padding-left: 10px;
    padding-right: 10px;
}

.start .slider .buttons{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
}

.start .slider .buttons input{
  background: #5C755E;
    height: 3rem;
    width: 6rem;
    border: 1px solid white;
    border-radius: 1rem;
    color:white;
}

.gateway-section h2{
  text-align: center;
  word-spacing: 13px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.gateway-section {
  position: relative;
  margin-top: 30%;
}

#gatewayCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gateway-section .Image-text {
  z-index: 1;
  display: grid;
  align-items: center;
  justify-content: space-between;
  height: 65vh;
  background-color: rgba(230, 225, 225, 0.241); 
  border-radius: 0.2rem;
  width: 95%;
  margin: 3rem auto;

}

.gateway-section .Image-text {
  z-index: 1;
  display: inline;
  width: 100%;
  max-width: fit-content;


}
.image img {
  width: 400px;
  height: auto;
}

.image {
  width: 100%;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.text {
  padding: 0px 10px;
  width: 100%;
}

h5, .text h3 {
  display: flex;
  justify-content: center;
  word-spacing: 13px;
  color: #A97D5D;
  font-weight: 900;
  font-size: 21px;
}

.text p {
  margin-top: 2rem;
  word-spacing: 8px;
  font-family: Arial;
  color: rgb(108, 108, 108);
}

.Products{
  background: transparent;
  height: fit-content;
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
  margin-top: 1rem;
}


.Products .product-slider{
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto; 
  height: fit-content;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
.Products .more{
  text-decoration: none;
  justify-content: flex-end;
  position: absolute;
  right: 0; 
  color: #5C755E;
  word-spacing: 10px;
  font-size: 20px;
}
.Products h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 13px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Products h2 .more{
  text-decoration: none;
  justify-content: flex-end;
  position: absolute;
  right: 5%; 
  color: #A97D5D;
  word-spacing: 10px;
  font-size: medium;
  margin-top: 60%;
  z-index: 10;
}

.Products .product-slider .product{
  height: fit-content;
  width: 90%;
  margin-right: 0.4rem;
  margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  align-content: center;
  justify-items:center;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}
.Products .product-slider .product img{
  width: 100%;
  height: 25vh;
  
}

.Products .product-slider .product  h3{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color: #5C755E;
  font-weight: 500;
  font-size: large;
  width: 100%;
}


.our-partners {
  width: 100%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.our-partners h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.4rem;
}

.our-partners .img{
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: space-between;
}

.our-partners .img img{
  height: 80%;
  width: 100px;
}

.partition1 {
  display: none;
  visibility: hidden;
}

.blog h2{
  display: flex;
  justify-content: center;
  color: #A97D5D;
}
.blog{
  height: 61vh;
  width: 100%;
}
.blogs{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 70vh;
  border-radius: 1rem;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.Blog-card{
  width: 95%;
  height: 70vh;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 0.2rem;
  overflow: hidden;
  border-top-right-radius: 2rem;
}

.Blog-card .img{
  width: 100%;
  height: 30vh;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 2rem;
  overflow: hidden;
}


.Blog-card .img img{
  width: 100%;
  height: 100%;
}

.Blog-card .text{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 30vh;
}

.Blog-card .text h2{
  width: 90%;
  font-size: 15px;
  color: #5C755E;
}

.Blog-card .text p{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}


.mg-txt {
  display: grid;
  grid-template-columns: 100%;
  width: 95%;
  margin-left: auto;
  height: fit-content;
  overflow: hidden;
  margin-right: auto;
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: relative;
}

.mg-txt .img {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
}

.mg-txt .img img {
  height: 100%;
  width: 100%;
}

.mg-txt .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  word-spacing: 10px;
  font-size: 20px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto 0.1rem;
  position: relative;
}

.mg-txt .txt p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Mobile-app {
  width: 100%;
  height: fit-content;
  position: relative;
}

.Mobile-app h2 {
  justify-content: center;
  display: flex;
  color: #A97D5D;
}

#mobileCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}





/*
Contact Doctor Area Style
======================================================*/
.contact-doctor-area {
margin-top: 1rem;
  overflow: hidden;
}

.contact-doctor-contain .contact-title span {
  color: #A97D5D;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.contact-doctor-contain .contact-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}
.contact-doctor-contain .contact-title p a{
  text-decoration: none;
  color: #A97D5D;
  font-size: 20px;
}
.contact-doctor-contain .contact-title p {
  margin-bottom: 10px;
  display: flex;
}
.contact-doctor-contain .contact-title span{
color: black;
font-size: 20px;
}
.contact-doctor-form .form-group {
  margin-bottom: 25px;
}

/*

/*
Footer Doctor Area Style
======================================================*/
.footer-area {
  background-color: white;
}

.logo-area {
  margin-bottom: 30px;
  padding-right: 30px;
}

.logo-area img {
  margin-bottom: 30px;
  height: 60px;
}

.logo-area p {
  color: #A97D5D;
  margin-bottom: 30px;
}

.logo-area .newsletter-form {
  position: relative;
}

.logo-area .newsletter-form .form-control {
  height: 53px;
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.45);
  color: #A97D5D;
  overflow: hidden;
}




.quick-link {
  margin-bottom: 30px;
}

.quick-link h3 {
  font-size: 20px;
  color: #A97D5D;
  margin-bottom: 30px;
}

.quick-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.quick-link ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 13px;
}

.quick-link ul li i {
  position: absolute;
  left: 0;
  color: #A97D5D;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li p {
  color: #A97D5D;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li:last-child {
  margin-bottom: 0;
}

.quick-link ul li:hover i {
  color: #5C755E;
}

.quick-link ul li:hover p {
  color: #5C755E;
}

.helpful-link {
  margin-bottom: 30px;
}

.helpful-link h3 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #A97D5D;
}

.helpful-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.helpful-link ul li {
  list-style-type: none;
  color: #A97D5D;
  margin-bottom: 20px;
}

.helpful-link ul li span {
  color: #5C755E;
  font-weight: 500;
  padding-left: 5px;
  display: inline-block;
}

.helpful-link ul li:last-child {
  margin-bottom: 0;
}

.helpful-link ul li a {
  color: #A97D5D;
}


.copyright {
  border-top: 1px solid rgba(12, 184, 182, 0.25);
  padding: 30px 0;
}

.copyright .copy p {
  color: #A97D5D;
}

.copyright .copy p a {
  color: #A97D5D;
}

.copyright .social-content {
  text-align: right;
}

.copyright .social-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.copyright .social-content ul li {
  display: inline-block;
  margin-right: 10px;
}

.copyright .social-content ul li:last-child {
  margin-right: 0;
}

.copyright .social-content ul li span {
  color: #A97D5D;
}

.copyright .social-content ul li a {
  color: #A97D5D;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.copyright .social-content ul li a:hover {
  color: #A97D5D;
}


}

/* Responsive styles for mobile */
@media only screen and (max-width: 767px) {
  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-item{
    padding: 5px 15px;
  }

  .navbar-brand{
    padding: 5px;
    height: auto;
    width: 40%;
     display: block;
     margin-right: 0px;
     filter: brightness(100%);
     -webkit-transition : all 0.3s ease-out;
     transition : all 0.3s ease-out;
   }

   .navbar-brand img{
    padding: 5px;
    height: auto;
    width: 70px;
     display: block;
     filter: brightness(100%);
     -webkit-transition : all 0.3s ease-out;
     transition : all 0.3s ease-out;
     
   }
 
  .start{
    width: 100%;
    display: flex;
    height: 70vh;
    background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
    background-size: fill;
    background-position: center center;
    background-attachment: fixed;
}

.start1{
  width: 100%;
  display: flex;
  height: 70vh;
  background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
}


.start2{
  width: 100%;
  display: flex;
  height: 70vh;
  background:url(../public/assets/img/Banner/Grandeur_smart_banner_1a.jpg);
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
}
.start .slider,
.start1 .slider,
.start2 .slider{
    width: 100%;
    
background: rgba(255, 255, 255, 0.449);
height: fit-content;
}

.start .slider h2{
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    color:#A97D5D;
    display: flex;
    margin-top: 1rem;
    width: 95%;
    font-family: sans-serif;
    font-weight: 900;
}

.start .slider p{
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    color: #A97D5D;
    display: flex;
    margin-top: 1rem;
    width: 100%; 
    font-family: sans-serif;
    padding-left: 10px;
padding-right: 10px;
}

.start .slider .buttons{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
}

.start .slider .buttons input{
  background: #5C755E;
    height: 3rem;
    width: 6rem;
    border: 1px solid white;
    border-radius: 1rem;
    color:white;
}

.landing-second-setion {
  padding: 0px 5px;
}

.gateway-section {
  position: relative;
  margin-top: 30%;
  width: 100%;
}


#gatewayCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gateway-section .Image-text {
  z-index: 1;
  display: inline;
  width: 100%;
  max-width: fit-content;


}

.image {
  width: 100%;
  padding: 0px 5px;
}

.text {
  padding: 0px 5px;
  width: 100%;
}


.Products{
  background: transparent;
  height: fit-content;
  background-size: fill;
  background-position: center center;
  background-attachment: fixed;
  margin-top: 1rem;
}

.Products .our-products {
  font-size: x-large;
}


.Products .product-slider{
  display: flex;
  width: 95%;
  margin-left: auto;
  margin-right: auto; 
  height: fit-content;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
.Products .our-products span{
  text-decoration: none;
  justify-content: flex-end;
  position: absolute;
  right: 0; 
  color: #5C755E;
  font-size: xx-small;
}
.Products h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 13px;
  color:#A97D5D;
  font-weight: 900;
  font-size: 29px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Products h2 .more{
  text-decoration: none;
  justify-content: flex-end;
  position: absolute;
  right: 5%; 
  color: #5C755E;
  word-spacing: 10px;
  font-size: x-small;
  margin-top: 830px;
  z-index: 10;
}


.Products .product-slider .product{
  height: fit-content;
  width: 90%;
  margin-right: 0.4rem;
  margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  align-content: center;
  justify-items:center;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}
.Products .product-slider .product img{
  width: 100%;
  height: 50vh;
  
}

.Products .product-slider .product  h3{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color: #5C755E;
  font-weight: 500;
  font-size: large;
  width: 100%;
}


.our-partners {
  width: 100%;
  height: fit-content;
  
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.our-partners h2{
  display: flex;
  align-items: center;
  justify-content: center;
  word-spacing: 7px;
  color:#A97D5D;
  font-weight: 900;
  font-size: x-large;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.4rem;
}

.our-partners .img{
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: space-between;
}

.our-partners .img img{
  height: 80%;
  width: 100px;
}
.bullet1 li  {
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
  /* color: rgb(108, 108, 108); */
  text-align: left;
  text-justify: inter-word;
}

.bullet2 li  {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500 ;
  text-align: left;
  margin-top: 0.5rem;
  word-spacing: 8px;
  font-family: Arial;
  color: #5C755E;
}

.partition{
  display: none;
  visibility: hidden;
}

.partition1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.partition1 .part1,
.partition1 .part2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 10px 0; /* Add some margin for spacing between parts */
}

.partition1 .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Add some margin for spacing between image and text */
}

.partition1 .logo img {
  width: 90%;
  height: auto;
}

.partition1 .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.partition1 .text p {
  font-size: 20px;
  font-weight: 300;
  color: #5C755E;
  width: 100%; /* Adjust the width to match the image width */
  font-size: large;
}

.blog h2{
  display: flex;
  justify-content: center;
  color: #A97D5D;
  font-size: x-large;
}
.blog{
  height: 75vh;
  width: 100%;
  overflow: hidden;
  
}
.blogs{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 70vh;
  border-radius: 1rem;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.Blog-card{
  width: 95%;
  height: 70vh;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  border-radius: 0.2rem;
  overflow: hidden;
  border-top-right-radius: 2rem;
}

.Blog-card .img{
  width: 100%;
  height: 30vh;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 2rem;
  overflow: hidden;
}


.Blog-card .img img{
  width: 100%;
  height: 100%;
}

.Blog-card .text{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 30vh;
}

.Blog-card .text h2{
  width: 90%;
  font-size: 15px;
  color: #5C755E;
}

.Blog-card .text p{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}


.mg-txt {
  display: grid;
  grid-template-columns: 100%;
  width: 95%;
  margin-left: auto;
  height: fit-content;
  overflow: hidden;
  margin-right: auto;
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: relative;
}

.mg-txt .img {
  width: 100%;
  height: 100%;
  margin-right: 2rem;
}

.mg-txt .img img {
  height: 100%;
  width: 100%;
}

.mg-txt .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  word-spacing: 10px;
  font-size: 20px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto 0.1rem;
  position: relative;
}

.mg-txt .txt p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Mobile-app {
  width: 100%;
  height: fit-content;
  position: relative;
}

.Mobile-app h2 {
  justify-content: center;
  display: flex;
  color: #A97D5D;
  font-size: x-large;
}

#mobileCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}







/*
Contact Doctor Area Style
======================================================*/
.contact-doctor-area {
margin-top: 1rem;
  overflow: hidden;
}

.contact-doctor-contain .contact-title span {
  color: #A97D5D;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.contact-doctor-contain .contact-title h2 {
  font-size: large;
  margin-bottom: 20px;
  font-weight: 900;
}
.contact-doctor-contain .contact-title p a{
  text-decoration: none;
  color: #A97D5D;
  font-size: 20px;
}
.contact-doctor-contain .contact-title p {
  margin-bottom: 10px;
  display: flex;
}
.contact-doctor-contain .contact-title span{
color: black;
font-size: 20px;
}
.contact-doctor-form .form-group {
  margin-bottom: 25px;
}

.align-items-cente {
  display: block;
}



/*

/*
Footer Doctor Area Style
======================================================*/
.footer-area {
  background-color: white;
}

.logo-area {
  margin-bottom: 30px;
  padding-right: 30px;
}

.logo-area img {
  margin-bottom: 30px;
  height: 40px;
}

.logo-area p {
  color: #5C755E;
  margin-bottom: 30px;
}

.logo-area .newsletter-form {
  position: relative;
}

.logo-area .newsletter-form .form-control {
  height: 53px;
  border-radius: 30px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.45);
  color: #A97D5D;
  overflow: hidden;
}



.quick-link {
  display: none;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 30px;
}

.quick-link h3 {
  font-size: 20px;
  color: #A97D5D;
  margin-bottom: 30px;
}

.quick-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.quick-link ul li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 13px;
}

.quick-link ul li i {
  position: absolute;
  left: 0;
  color: #A97D5D;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li p {
  color: #5C755E;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.quick-link ul li:last-child {
  margin-bottom: 0;
}

.quick-link ul li:hover i {
  color: #A97D5D;
}

.quick-link ul li:hover p {
  color: #5C755E;
}

.helpful-link {
  display: none;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 30px;
}

.helpful-link h3 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #A97D5D;
}

.helpful-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.helpful-link ul li {
  list-style-type: none;
  color:#5C755E;
  margin-bottom: 20px;
}
.helpful-link ul li:nth-child(2) {
  display: none;
}
.helpful-link ul li:nth-child(3) {
  display: none;
}
.helpful-link ul li span {
  color: #A97D5D;
  font-weight: 500;
  padding-left: 5px;
  display: inline-block;
}

.helpful-link ul li:last-child {
  margin-bottom: 0;
}

.helpful-link ul li a {
  color: #5C755E;
}

.helpful-link ul {
  padding-left: 0;
  margin-bottom: 0;
}

.helpful-link2 {
  display: inline;
}

.helpful-link2 p span {
  color: #A97D5D;
  font-weight: 500;
  display: inline-block;
  font-size: larger;
}

.helpful-link2 .footer-contact {
  display: flex;
}

.helpful-link2 .footer-contact ul {
  list-style-type: none;
  padding-left: 5px;
}

.helpful-link2 .footer-contact li {
   color: #5C755E;
   margin-top: 5px;
}


.helpful-link2 p:last-child {
  margin-bottom: 0;
}

.helpful-link2 p a {
  color: #5C755E;
}

.last {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.last img {
  margin: 0px 0px;
}



.copyright {
  border-top: 1px solid rgba(12, 184, 182, 0.25);
  padding: 30px 0;
}

.copyright .copy p {
  color: #A97D5D;
}

.copyright .copy p a {
  color: #A97D5D;
}

.copyright .social-content {
  text-align: right;
}

.copyright .social-content ul {
  padding-left: 0;
  margin-bottom: 0;
}

.copyright .social-content ul li {
  display: inline-block;
  margin-right: 10px;
}

.copyright .social-content ul li:last-child {
  margin-right: 0;
}

.copyright .social-content ul li span {
  color: #A97D5D;
}

.copyright .social-content ul li a {
  color: #A97D5D;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.copyright .social-content ul li a:hover {
  color: #A97D5D;
}
}










