.video-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .video {
    flex: 1 1 calc(25% - 10px); /* Four videos per row */
    box-sizing: border-box;
    padding: 5px;
  }
  
  .video iframe {
    width: 100%;
    height: 200px;
  }
  
  .video p {
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
  }

  @media only screen and (max-width: 767px) {

    .video-grid {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
    }
    
    .video {
      flex: 1 1 calc(25% - 10px); /* Four videos per row */
      box-sizing: border-box;
      padding: 5px;
    }
    
    .video iframe {
      width: 100%;
      height: 200px;
    }
    
    .video p {
      text-align: center;
      margin-top: 5px;
      font-size: 14px;
    }
    
  }
  