.estate-plans-page {
    width: 100;
    height: auto;
  
}

.estate-plans-page h1 {
    color: #5C755E;
    text-align: center;
    margin: 20px 0;
}

.estate-plans-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

@media (max-width: 768px) {
    .estate-plans-list {
        flex-direction: column;
        align-items: center;
    }
}
