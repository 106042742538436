.customize-kit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
}

h2 {
    color: #5C755E;
    margin-bottom: 20px;
}

.product-kit, .product-grid, .selected-items, .total-price {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.customize-product-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    justify-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    transition: transform 0.3s;
    margin: 10px;
}

.product-card:hover {
    transform: translateY(-5px);
}

.img img {
    width: 50%;
    height: auto;

}

.details {
    padding: 10px;
}

.product-name {
    font-size: 18px;
    color: #5C755E;
    margin: 10px 0;
}

.product-description {
    font-size: 14px;
    color: #777;
    margin: 10px 0;
}

.price {
    font-size: 16px;
    color: #A97D5D;
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
}

.buy {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.buy button {
    background: #A97D5D;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.buy button:hover {
    background: #9a6a52;
}

.buy input {
    width: 50px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.search-bar {
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.navigation-buttons button {
    background: #5C755E;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.navigation-buttons button:disabled {
    background: #ddd;
    cursor: not-allowed;
}

.selected-items {
    margin-top: 2%;
}

.selected-items ul {
    list-style: none;
    padding: 0;
}

.selected-items li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.selected-items input {
    width: 50px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}

.total-price h3 {
    color: #A97D5D;
    font-size: 20px;
    margin-top: 20px;
}

.customize-kit-container h2, .selected-items h3, .total-price h3 {
    text-align: center;
}

.quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.buy .quantity-controls .quantity-button {
    color: #5C755E;
    border: solid 1px;
    border-radius: 5px;
    padding: 5px 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}

.buy .quantity-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #A97D5D;;
    color: #5C755E;
}

.buy .quantity {
    margin: 0 10px;
    font-size: 1rem;
}



