.social-media {
    text-align: center;
    margin-top: 20px;
  }
  
  .social-media ul {
    list-style-type: none;
    padding: 0;
  }
  
  .social-media ul li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .social-media ul li a {
    font-size: 24px; /* Adjust as needed */
    transition: color 0.3s ease;
  }
  
  .social-media ul li a .fa-facebook-f {
    color: #3b5998; /* Facebook blue */
  }
  
  .social-media ul li a .fa-twitter {
    color: #1da1f2; /* Twitter blue */
  }
  
  .social-media ul li a .fa-instagram {
    color: #e4405f; /* Instagram gradient start */
    background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888); /* Instagram gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .social-media ul li a .fa-linkedin-in {
    color: #0077b5; /* LinkedIn blue */
  }
  
  .social-media ul li a:hover {
    color: #007bff; /* Optional: Add a hover color */
  }
  