
  
  /* Admin Dashboard Layout */
  .admin-dashboard {
    display: flex;
    height: 100vh;
  }
  
  /* Sidebar Styles */
  .sidebar {
    width: 250px;
    background-color: #5C755E;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .sidebar-header {
    padding: 20px;
    text-align: center;
    background-color: whitesmoke;
  }
  
  .sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-nav li {
  margin: 15px 0;
  padding: 15px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(92, 117, 94, 0.1);
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
}
  
  .sidebar-nav li a {
    color: white;
    text-decoration: none;
    
  }

  .sidebar-nav li:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    border-bottom: 2px solid white;
    
  }
  
  .sidebar-nav li a:hover {

    border-radius: 5px;
    color: #5C755E;
    
  }
  
  .sidebar-footer {
    padding: 20px;
    text-align: center;
  }
  
  .sidebar-footer button {
    background-color: #A97D5D;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .sidebar-footer button:hover {
    background-color: #ffffff;
    color: #A97D5D;
    border: 1px solid #A97D5D;
  }
  
  /* Main Content Styles */
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
  }
  .chatroom-ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .chatroom-ul li {
    margin: 10px;
  }

  .chatroom-ul li button {
    display: flex;
    justify-content: space-between;

  }
  
  .chatroom-ul li button p {
    margin-bottom: 0px;
    margin-right: 5px;
    
  }

  .chatroom-assign-chat {
    background-color: #5C755E;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chatroom-assign-chat:hover {
    background-color: #A97D5D;
  }

  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
    }
  
    .main-content {
      padding: 10px;
    }
  }
  