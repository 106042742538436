.carousel-video-container {
    width: 100%;
    height: 70vh;
    background-color: #291608;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transform: scaleX(-1); /* Mirror the video horizontally */
}

.carousel-content {
    position: relative;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

.carousel-item-contnt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
    margin: auto;
}

.carousel-caption-container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: zoomInBottom 1.5s ease-in-out forwards;
    opacity: 0;
}

.carousel-caption {
    text-align: center;
}

.carousel-captio h2 {
    color: #A97D5D;
    font-size: 2.5em;
    font-weight: bold;
    margin: 0;
}

.carousel-buttos {
    width: 45%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    animation: fadeInRight 1.5s ease-in-out forwards;
    opacity: 0;
    text-align: center;
}

.carousel-butto {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 1.2em;
}





.customize-button {
    background-color: rgba(92, 117, 94, 0.3);
    border: 2px solid #5C755E;
}

.customize-button:hover {
    background-color: #5C755E;
    border: 2px solid #5C755E;
}

@keyframes zoomInBottom {
    0% {
        transform: translateY(100%) scale(0.5);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
