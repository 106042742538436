.checkout-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkout-title {
    text-align: center;
    margin-bottom: 20px;
    color: #5C755E;
}

.checkout-list {
    list-style-type: none;
    padding: 0;
}
.checkout-div {
    
    border-bottom: 1px solid #ccc;
}

.checkout-item {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 10px 0;
    align-items: center;
    
}

.checkout-quantity-heading {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    color: gray;
    font-size: small;
}

.checkout-quantity {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    color: gray;
    font-size: small;
}

.checkout-item-name {
    flex-grow: 1;
}

.checkout-item-price {
    color: #A97D5D;
}

.checkout-total {
    text-align: right;
    font-weight: bold;
    margin-top: 20px;
}

.checkout-form {
    margin-top: 20px;
}

.checkout-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.input-error {
    border-color: red;
}

.error-text {
    color: red;
    margin-bottom: 10px;
}

.checkout-button {
    width: 100%;
    padding: 10px;
    background-color: #5C755E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #A97D5D;
}

.empty-cart-message {
    text-align: center;
    margin-top: 20px;
}

.continue-shopping-button {
    padding: 10px 20px;
    background-color: #5C755E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.continue-shopping-button:hover {
    background-color: #A97D5D;
}

.paystack-button {
    display: none;
}
