/* CartPage.css */

.cart-container {
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-title {
    font-size: 2rem;
    color: #5C755E;
    text-align: center;
    margin-bottom: 20px;
}

.cart-empty {
    font-size: 1.2rem;
    color: #A97D5D;
    text-align: center;
    margin-top: 40px;
}

.cart-list {
    list-style: none;
    padding: 0;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.cart-item-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.cart-item-details {
    flex: 1;
    margin-left: 20px;
}

.cart-item-name {
    font-size: 1rem;
    color: #333;
}

.cart-item-price {
    font-size: 1rem;
    color: #5C755E;
    margin-top: 5px;
}

.cart-item-remove {
    background-color: rgba(255, 255, 255, 0.1);
    color: #A97D5D;
    border: 1px solid #A97D5D;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.cart-item-remove:hover {
    background-color: #A97D5D;
    border: none;
    color: #fff;
}

.quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.quantity-button {
    color: #A97D5D;
    border: none;
    border-radius: 5px;
    padding: 5px 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.quantity-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #5C755E;
    color: #5C755E;
}

.quantity {
    margin: 0 10px;
    font-size: 1rem;
}

.cart-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.cart-continue-shopping {
    color: #5C755E;
    text-decoration: none;
    font-size: 1rem;
}

.cart-checkout-button {
    background-color: #5C755E;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.cart-checkout-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #5C755E;
    color: #5C755E;
}
