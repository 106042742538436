.packages .right .product-text .star-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    /* color: #5C755E; */
    /* color: #A97D5D; */
  }


  .packages .right .product-text .star-rating .small-stars{
    width: 100%;
  height: auto;
  }
  
  .star-rating .dv-star-rating {
    
    font-size: 1rem;
    color: #ffd700;
    cursor: pointer;
  }
  
  .star-rating .dv-star-rating .dv-star-rating-empty-star::before {
    position: absolute;
    color: #e0e0e0; /* Light grey color for empty stars */
  }
  
  .star-rating .dv-star-rating .dv-star-rating-filled-star::before {
    content: '\u2605'; /* Unicode character for filled star */
  }
  
  .packages .right .product-text .star-rating .star-p {
    font-size: 1rem;
    margin-bottom: auto;
    padding-left: 1%;
    color: darkgrey;
  }
  