.chatBox {
    height: 60vh; /* Fixed height */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.message {
    display: flex;
    margin-bottom: 10px;
}

.message-content {
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
}

.message.admin .message-content {
    background-color: #A97D5D; /* Admin message background color */
    color: #ffffff; /* Admin message text color */
    align-self: flex-start;
}

.message.user .message-content {
    background-color: #5C755E; /* User message background color */
    color: #ffffff; /* User message text color */
    align-self: flex-end;
}

.message-time {
    font-size: 0.8rem;
    margin-top: 5px;
    color: #888;
}

.messageInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0;
    border-top: 1px solid #ccc;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.messageInput textarea {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    resize: none;
}

.messageInput button {
    padding: 10px 20px;
    background-color: #A97D5D; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.messageInput button:hover {
    background-color: #874c3c; /* Hover state background color */
}
